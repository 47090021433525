import React, { ReactElement } from 'react';
import {
    Container,
    HeroContainer,
    TwoColumn,
    LeftColumn,
    Heading,
    Notification,
    Button,
    RightColumn,
    ButtonContainer,
    ImageMobile,
} from './Hero.styled';
import { useTranslation } from 'react-i18next';
import './modal-video.min.css';
import { motion } from 'framer-motion';

interface HeroProps {
    title: string;
    image: ReactElement;
    activeButton?: string;
    subtitle?: string;
}

export const Hero = ({ title, subtitle, activeButton, image }: HeroProps) => {
    const { t } = useTranslation();
    return (
        <Container>
            <HeroContainer>
                <TwoColumn>
                    <LeftColumn>
                        <motion.div>
                            <Heading> {title} </Heading>
                            {subtitle && (
                                <Notification>{subtitle}</Notification>
                            )}
                        </motion.div>
                    </LeftColumn>
                    <RightColumn>{image}</RightColumn>
                </TwoColumn>
                <ButtonContainer>
                    <Button
                        to="/for-hospitals"
                        className={`${
                            activeButton === 'for-hospitals' ? 'active' : ''
                        }`}
                    >
                        {t('hero.hospitalsButton')}
                    </Button>
                    <Button
                        to="/for-healthcare-providers"
                        className={`${
                            activeButton === 'healthcare-providers'
                                ? 'active'
                                : ''
                        }`}
                    >
                        {t('hero.healthcareProvidersButton')}
                    </Button>
                    <Button
                        to="/for-technology-partners"
                        className={`${
                            activeButton === 'technology-partners'
                                ? 'active'
                                : ''
                        }`}
                    >
                        {t('hero.technologyPartnersButton')}
                    </Button>
                </ButtonContainer>
            </HeroContainer>
        </Container>
    );
};
