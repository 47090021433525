import tw, { theme } from 'twin.macro';
import styled, { css } from 'styled-components';
import HeroBtn1 from 'static/images/heroBtn.svg';
import HeroBtn2 from 'static/images/heroBtn2.svg';
import { LocaleLink } from '@/components/LocaleLink';

interface ImageWrapProps {
    noButton?: boolean;
}

export const Container = styled.div`
    @media (min-width: ${theme`screens.lg`}) {
        padding-top: 140px;
    }
`;
export const HeroContainer = tw.div` relative max-w-screen-xl mx-auto`;
export const TwoColumn = tw.div`pt-24 xl:pt-12 flex justify-between items-center flex-col lg:flex-row  md:items-start`;
export const LeftColumn = tw.div`flex lg:w-1/2 flex-col items-start `;
export const RightColumn = styled.div`
    ${tw`w-full lg:w-1/2 relative`};

    .index-hero {
        position: absolute;
        max-width: 80%;
        margin: auto;
        left: 0;
        right: 0;

        @media (max-width: ${theme`screens.lg`}) {
            position: inherit;
        }
    }
`;
export const Heading = styled.h1<{ isALt?: boolean }>(({ isALt }) => [
    tw`whitespace-pre-line text-3xl text-left sm:text-4xl lg:text-5xl font-black text-gray-100 text-black mb-8 leading-xs`,
    `  @media (max-width: ${theme`screens.lg`}) {
            font-size: clamp(12px,7vw,33px);
            min-height: unset;        
            h1{
                max-width: 506px;
            }
        }
        min-height: 300px;        
`,
]);
export const Notification = tw.span`inline-block mb-7 py-1 text-gray-100 font-medium md:text-lg text-black`;

export const Button = styled(LocaleLink)`
    ${tw`no-underline hover:bg-purple-400 hover:text-white bg-white border-2 text-purple-400 border-purple-400  rounded-xl capitalize outline-none transform active:scale-102 mr-auto flex items-center justify-center text-center`}
    height: 48px;
    width: 140px;
    max-width: 200px;
    transition: 0.2s;

    &:hover {
        text-decoration: none;
    }

    &.active {
        ${tw`bg-purple-400 text-white`}
    }
`;

export const ButtonContainer = styled.div`
    ${tw`grid grid-cols-3 gap-1 lg:gap-4 w-1/2 mt-12 lg:mt-2`}
    grid-template-columns: 2fr 3fr 3fr;

    @media (max-width: ${theme`screens.lg`}) {
        ${tw`lg:mt-12 w-full`}
        grid-template-columns: 1fr;
        a {
            text-align: center;
        }
    }

    a {
        ${tw`w-full text-tiny font-semibold`}
        max-width: unset;
    }
`;

export const twImageWrap = tw.div`relative lg:absolute cursor-pointer transform md:-translate-y-24`;

export const ImageWrap = styled(twImageWrap)<ImageWrapProps>`
    ${(props) =>
        !props.noButton &&
        css`
            &:after {
                transition: 0.1s ease-in;
                position: absolute;
                top: calc(50% - 90px);
                left: calc(50% - 90px);
                content: '';
                background-image: url(${HeroBtn1});
                width: 180px;
                height: 180px;
                background-size: 100%;
                background-position: cover;
                background-repeat: no-repeat;
            }
            &:before {
                transition: 0.1s ease-in;
                position: absolute;
                top: calc(50% - 90px);
                left: calc(50% - 90px);
                content: '';
                background-image: url(${HeroBtn2});
                width: 180px;
                height: 180px;
                background-size: 100%;
                background-position: cover;
                background-repeat: no-repeat;
            }
        `}
    &:hover {
        &:after {
            opacity: 0;
        }
    }
`;
export const Image = styled.img`
    min-width: 100%;
`;

export const ImageMobile = styled.div`
    @media (min-width: ${theme`screens.lg`}) {
        display: none;
    }
`;
