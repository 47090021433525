import React, { useEffect } from 'react';
import { Hero } from '@/components/Hero';
import Seo from '@/components/seo.js';
import { useTranslation } from 'react-i18next';
import Img from '../../static/images/HomeHero.png';
import EFRELogo from 'static/images/EFRE_EU_logo.jpg';

const IndexPage = ({ path }) => {
    const { t } = useTranslation();

    return (
        <>
            <Seo title="Medrefer" />
            <script
                type="text/javascript"
                src="src/layouts/Klaro/config.js"
            ></script>
            <section id="hero" className="index-hero-section">
                <Hero
                    title={t('home.heroTitle')}
                    image={<img src={Img} className="index-hero" alt="hero" />}
                />
            </section>
            <section id="euro-logo">
                <div className="efre-logo-container">
                    <img src={EFRELogo} alt="EFRE EU Logo" />
                </div>
            </section>
        </>
    );
};

export default IndexPage;
